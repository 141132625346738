<script>
import Loading from "./LoadingPage.vue"
export default {
  data(){
    return{
    timer: true
    }
  },
  mounted() {
    setTimeout(()=>{
      this.timer =false;
    },3000)
  },
  components: {
    Loading
  }
}
</script>

<template>
<div>
  <Loading v-if="timer"/>
 
</div>
</template>

<style scoped>

</style>