<template>
  <VideoBackground>
  <indexPage/>
  <main>
    <RouterView />
  </main>
  </VideoBackground>
</template>

<script>
import indexPage from "@/components/IndexPage.vue";
import VideoBackground from "@/components/backgroundVideo.vue";

export default {
components: {VideoBackground, indexPage}

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
body {
  font-family: "Noto Sans KR", serif;

}
</style>
