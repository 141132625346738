<template>
  <div :class="['card', { 'bounce': isBouncing }]" @animationend="removeBounce">
    <img :src="image" alt="Card image" class="card-image" v-if="image" />
    <div class="card-content">
      <h3 class="card-title">{{ title }}</h3>
      <p class="card-text" v-html="content"></p>
      <button @click="onButtonClick" class="card-button">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentCard",
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: "Learn More"
    },
    image: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isBouncing: false
    };
  },
  methods: {
    onButtonClick() {
      this.$emit("buttonClick");
      this.isBouncing = true;
    },
    removeBounce() {
      this.isBouncing = false;
    }
  }
};
</script>

<style scoped>
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 900px;
  transition: transform 0.3s ease;

}

.hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-title {
  font-size: 40px;
  margin: 0;
  color: #333;
}

.card-text {
  color: #323232;
  margin: 52px 0;
  font-size: 20px;
  font-weight: 600;
}

.card-button {
  background-color: #1a3765;
  border: none;
  color: white;
  padding: 10px 16px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-button:hover {
  background-color: #4e86d6;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation: bounce 0.3s ease;
}
</style>
