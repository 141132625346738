<script>

import {defineComponent} from "vue";
import ContentCard from "@/components/ContentCard.vue";
import {businessPlanData} from "@/data/businessPlan";

export default defineComponent({
  components: {ContentCard},
  data() {
    return{
      businessPlanData,
      title: null,
      content: null,
      image: null,
      index: 0
    }
  },
  mounted() {

    if (this.businessPlanData.length > 0) {
      this.title = this.businessPlanData[this.index].title;
      this.content = this.businessPlanData[this.index].text;
      this.image = this.businessPlanData[this.index].image;
    }
  },
  methods:{
    buttonClick(){
      if (this.businessPlanData.length > this.index +1) {
        this.index++
        this.title = businessPlanData[this.index].title
        this.content = businessPlanData[this.index].text
        this.image = this.businessPlanData[this.index].image;
      }
    }
  }

})


</script>

<template>
<div class="cardContainer">
<ContentCard
:title=title
:content=content
button-text="다음"
@buttonClick="buttonClick"
/>
</div>
</template>

<style scoped>
.cardContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}
</style>