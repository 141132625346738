<script>

</script>

<template>
<div>
  <img class="logo" src="@/assets/logo/newfriendsLogo.png" alt="logo"/>
</div>
</template>

<style scoped>
div{
  animation: 1500ms fade-in-text;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: white;
  z-index: 100001;
}
@keyframes fade-in-text {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
</style>