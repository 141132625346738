export const businessPlanData = [
    {
        title: '엔로직이 새로운 전환점을 맞이합니다',
        text: '<div>2023년 시작한 엔로직은 베타서비스를 종료하고, <br> <span style="color: #894aa4 ">프렌즈 커머스</span>라는 새로운 이름으로 다시 시작합니다.</div>',

    }, {
        title: 'Simple is Best',
        text: '<div>프렌즈 커머스는 클릭 한 번으로 구매대행의 모든 로직을 담아낸 <br> 초 간편 구매대행 솔루션이 됩니다.</div>'
    },
    {
        title: '1. 주문처리 자동화',
        text: '<div>연계된 배달 대행지를 이용하여<br> 사용자 주문이 발생하면 클릭 한 번에 구매 대행부터 배달 대행까지 OK!</div> '
    },
    {
        title: '1. 주문처리 자동화',
        text: '<div>기존 구매대행의 로직 <br> 상품 탐색 -> 상세페이지 제작 -> 등록 -> 판매 -> 구매 -> 배대지 등록 -> 정산 <br> 이 불편한 서비스를 프렌즈 커머스가 혁신적으로 개선합니다!</div> '
    },
    {
        title: '1. 주문처리 자동화',
        text: '<div>프렌즈 커머스의 로직 <br> 수집 -> 등록 -> 판매 -> 정산 <br> 이제 상품이 팔려도 걱정하지 마세요.</div> '
    },
    {
        title: '',
        text: '<div style="font-size: 40px"> 주문 확인만 누르면 <span style="color: #894aa4 ">프렌즈 커머스</span>가 다 해주니까!</div> '
    },
    {
        title: '1. 주문처리 자동화',
        text: '<div> 상품 판매 이후 연계 배대지의 시스템에 구매, 배달 요청이 발생하고 <br> 이를 배대지에서 상품 구매와 배달까지 한 번에 처리합니다! <br> 클릭 한 번이면 구매대행 상품 판매가 끝!</div>'
    },
    {
        title: '1. 주문처리 자동화',
        text: '<div> 상품이 너무 많이 팔려도 고역이셨죠? <br> 손이 부족하니까 인건비 들어가셨죠? <br> 이제 <span style="color: #894aa4 ">프렌즈 커머스</span>가 해결합니다.</div>'
    },
    {
        title: '2. BM(비지니스 모델) 개선',
        text: '<div> 기존 구매대행 프로그램의 월 결제 지긋지긋하죠. <br> 무제한 쓰려면 수십만원, 사업자가 늘어나면 끝도 없는 추가 과금</div>'
    },
    {
        title: '2. BM(비지니스 모델) 개선',
        text: '<div> 프렌즈 커머스가 합리적으로 바꿉니다!</div>'
    },
    {
        title: '2. BM(비지니스 모델) 개선',
        text: '<div> 상품 수집 수량을 10개 단위로 살 수 있는 소규모 과금부터 <br> 상품 수집과 이미지 번역을 할인가에 제공하는 패키지까지 <br> 이제부터 상품수집 쓸만큼만 결제하고 합리적으로 쓰세요!</div>'
    },
    {
        title: '2. BM(비지니스 모델) 개선',
        text: '<div> 모바일 게임의 BM 구조를 벤치마킹하여 <br> 뉴프렌즈는 마치 게임같이 즐거운 부업을 할 수 있도록 만들어줍니다. <br> 수많은 선택권은 각기 다른 상황의 구매대행 사용자들에게 아주 매력적인 과금 구조가 될 것입니다.</div>'
    },
    {
        title: '3. 역직구 시스템 도입',
        text: '<div> 내 상품은 가지고 있는데, 판매처가 너무 한정적이네...</div>'
    },
    {
        title: '3.역직구 시스템 도입',
        text: '<div>  <span style="color: #894aa4 ">프렌즈 커머스</span>에서 해결합니다. <br> 쇼피, 아마존 등 다양한 해외 커머스 사이트와 협업하여 한국에서 해외로 상품을 판매할 수 있습니다!</div>'
    },
    {
        title: '3. 역직구 시스템 도입',
        text: '<div> 어려운 외국어, 복잡한 사이트, 판매되면 배송은? 걱정하지마세요! <br> 프렌즈 커머스의 해외 진출 원클릭 솔루션과 함께 <br> 5000만의 고객에서 60억의 고객으로 간편 도약하세요!</div>'
    },
    {
        title: '3. 역직구 시스템 도입',
        text: '<div> 프렌즈 커머스의 상품 정보를 등록하고 <br> 진출을 원하는 사이트를 클릭! <br> 연동이 완료되면 언제든 간편하게 해외 커머스에 상품을 등록할 수 있습니다.</div>'
    },
    {
        title: '3. 역직구 시스템 도입',
        text: '<div> 판매 알람과, 배송 처리까지 프렌즈 커머스에서 간편하게 이용 가능합니다.</div>'
    },
    {
        title: '4. 프렌즈 마켓 도입',
        text: '<div> 타오바오, 알리, 중국 커머스 사이트 탐색이 지겨우신가요? <br> 프렌즈 마켓은 중국 판매자가 직접 상품을 올리고 <br> 뉴프렌즈가 번역하여 상세페이지부터 썸네일까지 모든 이미지를 제공합니다!</div>'
    },
    {
        title: '4. 프렌즈 마켓 도입',
        text: '<div> 이제 영어를 몰라도, 중국어를 몰라도, 파파고와 구글 번역기는 안녕! <br> 스마트스토어에서 상품을 고르듯 편하게 도매 쇼핑하세요!</div>'
    },
    {
        title: '4. 프렌즈 마켓 도입',
        text: '<div> 모든 구매 금액은 프렌즈 캐시에서 간편 차감! 자동 충전 설정으로 걱정없이 매입하세요!</div>'
    },
    {
        title: '5. 프렌즈 커머스 앱 출시',
        text: '<div> 버스에서, 카페에서, 화장실에서도! 프렌즈 커머스로 상품을 수집하고 업로드하세요.</div>'
    },
    {
        title: '5. 프렌즈 커머스 앱 출시',
        text: '<div> 위 모든 기능을 모바일에서 간편하게, 부업이 아니라 게임하는 것 처럼!</div>'
    },
    {
        title: '5. 프렌즈 커머스 앱 출시',
        text: '<div> 판매 알림과 CS 처리까지 모두 대표님의 핸드폰 안에서 뚝딱, 해결할 수 있어요</div>'
    },
    {
        title: '6. 뉴프렌즈의 미래',
        text: '<div> 뉴프렌즈는 돈보다는 사람을, 속도보다는 기술을 우선하는 개발사가 됩니다. <br> 엔로직으로 시작한 뉴프렌즈의 서비스는 간편함과 간결함을 중심으로 사용자 경험을 최우선하는 개발사가 되어 <br> 사업자에게 필요한 모든 서비스를 제공할 것입니다.</div>'
    },
    {
        title: '6. 뉴프렌즈의 미래',
        text: '<div> 귀찮고, 힘들고, 짜증나고, 돈드는 모든 것을 <br> 간편하게 만들어드립니다.</div>'
    },
    {
        title: '6. 뉴프렌즈의 미래',
        text: '<div> Simple is Best, 뉴프렌즈의 미래는 간단합니다.</div>'
    },
]

