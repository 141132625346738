<script>
export default {
  computed: {
    today() {
      const date = new Date()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `<div style="float: right; margin: 10px 20px 0 0">${month} <br> ${day}</div>`
    }
  }
}
</script>

<template>
  <div class="landingWrapper">
    <div class="landingContainer_topBox_left">개발 문의</div>
    <RouterLink class="landingContainer_topBox_right" to="/plan">사업계획서</RouterLink>
<div class="landingContainer">
<div class="date" v-html="today"></div>
  <div class="title">주식회사 뉴프렌즈</div>
  <div class="text">함께하는 가치를 만듭니다</div>
  <p class="content">웹 사이트 제작, 프로그램 개발, 앱 개발 너무 멀기만 한 이야기 같나요? <br>뉴프렌즈는 외주 개발사가 아닌 대표님의 친구로써 함께합니다.<br> 어렵기만 한 개발용어, 의도를 알 수 없는 명세서, 요구서등 모두 곁에서 보조해드립니다. <br> 뉴프렌즈의 소통 전문가를 통해 최소한의 비용으로 최고의 프로그램을 만들어보세요.</p>
</div>
  </div>
</template>

<style scoped>
.landingWrapper{
  display: grid;
  place-items: center;
  position: relative;
  margin-top: 60px;
}
.landingContainer_topBox_left{
  background: #0080d8;
  width: 20vw;
  height: 8vh;
  margin-right: 55vw;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  z-index: 1001;
  text-align: center;
  padding: 27px 0 0 0;
  font-weight: 800;
  font-size: 25px;
  color: #ffffff;
  cursor: pointer;
}
.landingContainer_topBox_left:hover{
  animation: float 1s infinite linear;
}
.landingContainer_topBox_right{
  text-decoration: none;
  position: absolute;
  background: #fd9f28;
  width: 20vw;
  height: 8vh;
  margin-right: 25vw;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  top: 0;
  text-align: center;
  padding: 27px 0 0 0;
  font-weight: 800;
  font-size: 25px;
  color: #ffffff;
  cursor: pointer;
}
.landingContainer_topBox_right:hover{
  animation: float 1s infinite linear;
}
.landingContainer{
  background: #0080d8;
  width: 75vw;
  height: 75vh;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  z-index: 1002;
}

.date{
  font-weight: 800;
  font-size: 38px;
  line-height: 1.1;
  width: 100%;
  float: right;
  color: white;
}
.title{
  font-size: 70px;
  font-weight: 800;
  color: white;
  margin: 140px 0 0 80px;
}
.text{
  font-size: 40px;
  font-weight: 500;
  color: #ededed;
  margin: 10px 0 0 120px;
}

.content{
  margin: 80px 30px 0 60px;
  color: #f1f1f1;
  font-size: 20px;
}


@media (max-width: 873px) {
  .title{
    font-size: 35px;
  }
  .text{
    font-size: 20px;
  }
  .landingContainer_topBox_left{
    width: 30vw;
    margin-right: 45vw;
  }
  .landingContainer_topBox_right{
    width: 30vw;
    margin-right: 5vw;
  }
}

@media (max-width: 507px) {
  .title{
    margin: 140px 0 0 20px;
    font-size: 28px;
  }
  .text{
    margin: 40px 0 0 20px;
    font-size: 18px;
  }
  .date{
    font-size: 20px;
  }
  .landingContainer_topBox_left{
    width: 40vw;
    margin-right: 35vw;
  }
  .landingContainer_topBox_right{
    width: 40vw;
    margin-right: 0;
    margin-left: 35vw;
  }
}

@keyframes float {
  0%{transform: translateY(0)}
  80%{transform: translateY(10px)}
  100%{transform: translateY(0)}
}
</style>